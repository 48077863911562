import React from 'react';
import * as Styled from './footerStyles';
import { graphql, useStaticQuery } from 'gatsby';
import useMenu from '../../hooks/useMenus';
import Cookies from 'universal-cookie';
import { BLOCKS } from '@contentful/rich-text-types';
import { SECTIONS } from '../../constants';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
  isValidURLLanguage,
  validNodeLocale,
} from '../../utils';
import { useLocation } from '@reach/router';

export default function Footer() {
  const cookies = new Cookies();
  const location = useLocation();

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) && validNodeLocale(urlLanguage)
      ? validNodeLocale(urlLanguage)
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const unFilteredMenu = useMenu(selectedLanguage);

  const filteredMenu = unFilteredMenu?.find((section) =>
    cookies.get('region')?.toUpperCase()
      ? section?.abbreviation === cookies.get('region')?.toUpperCase()
      : section?.abbreviation === 'NB'
  );
  const menu = filteredMenu?.sections[0];
  const footerSectionsData = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulUltramarCommercial(
        filter: { identifier: { type: { eq: "footer" } } }
      ) {
        edges {
          node {
            node_locale
            sections {
              ... on ContentfulRegions {
                sections {
                  ... on ContentfulContent {
                    id
                    title
                    type
                    contentDetails {
                      raw
                    }
                    header
                  }
                  ... on ContentfulListOfImageWithPath {
                    id
                    type
                    title
                    listOfImagesWithPath {
                      id
                      title
                      type
                      path
                      image {
                        file {
                          fileName
                          url
                        }
                        title
                      }
                    }
                  }
                }
                abbreviation
                title
                imageWithPath {
                  title
                  type
                  path
                  image {
                    file {
                      fileName
                      url
                    }
                    title
                  }
                }
                textWithPath {
                  text
                  type
                  path
                }
              }
            }
            title
            slug
          }
        }
      }
    }
  `);
  const selectedRegion = cookies.get('region')?.toUpperCase()
    ? cookies.get('region')?.toUpperCase().toUpperCase()
    : 'NB';
  const regionLanguageFilteredData =
    footerSectionsData?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );
  const footerSections = regionLanguageFilteredData?.node?.sections?.filter(
    (region) => region?.abbreviation === selectedRegion
  )[0];
  const footerTrademarkData = footerSections?.sections?.filter((section) => {
    return section?.type === SECTIONS.FOOTER.FOOTERTRADEMARKTEXT;
  })[0];
  const socailMediaImagesData = footerSections?.sections?.filter((section) => {
    return section?.type === SECTIONS.FOOTER.IMAGEWITHPATH;
  })[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.FootBottomBottomP>{children}</Styled.FootBottomBottomP>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.Foot>
      <Styled.FootSitemapContainer>
        <Styled.FootSitemap>
          <Styled.MainMenuTitleUL>
            {menu?.navigations?.map((menu, index) => (
              <Styled.MainMenuTitleLI key={index}>
                <Styled.MainMenuTitle
                  href={
                    isExternalUrlHref(menu?.entryWithPath?.path)
                      ? menu?.entryWithPath?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL +
                            menu?.entryWithPath?.path
                        )
                  }
                  target={
                    isExternalUrl(menu?.entryWithPath?.path)
                      ? '_blank'
                      : '_self'
                  }
                >
                  {menu?.entryWithPath?.text}
                </Styled.MainMenuTitle>
                <Styled.SubMenuListUL>
                  {menu?.subNavigations && (
                    <>
                      {menu?.subNavigations?.map((subMenu, index) => (
                        <Styled.SubMenuListLI key={index}>
                          <Styled.SubMenuTitle
                            href={
                              isExternalUrlHref(
                                subMenu?.textWithPath?.path ?? subMenu?.path
                              )
                                ? subMenu?.textWithPath?.path ?? subMenu?.path
                                : addTrailingSlash(
                                    process.env.GATSBY_DOMAIN_URL +
                                      (subMenu?.textWithPath?.path ??
                                        subMenu?.path)
                                  )
                            }
                            target={
                              isExternalUrl(
                                subMenu?.textWithPath?.path ?? subMenu?.path
                              )
                                ? '_blank'
                                : '_self'
                            }
                          >
                            {subMenu?.textWithPath?.text ?? subMenu?.text}
                          </Styled.SubMenuTitle>
                        </Styled.SubMenuListLI>
                      ))}
                    </>
                  )}
                </Styled.SubMenuListUL>
              </Styled.MainMenuTitleLI>
            ))}
          </Styled.MainMenuTitleUL>
        </Styled.FootSitemap>
      </Styled.FootSitemapContainer>
      <Styled.FootBottom>
        <Styled.FootBottomLeft className="d-none d-lg-block">
          <Styled.FootBottomLeftUL>
            <Styled.FootBottomLeftLI>
              {/* <a
                href={
                  footerSections?.textWithPath?.length > 0 &&
                  isExternalUrlHref(footerSections?.textWithPath[0]?.path)
                    ? footerSections?.textWithPath[0]?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL +
                          footerSections?.textWithPath[0]?.path
                      )
                }
                target={
                  footerSections?.textWithPath?.length > 0 &&
                  isExternalUrl(footerSections?.textWithPath[0]?.path)
                    ? '_blank'
                    : '_self'
                }
                rel="noreferrer"
              >
                {footerSections?.textWithPath?.length > 0 &&
                  footerSections?.textWithPath[0]?.text}
              </a> */}
              {footerSections?.textWithPath?.length > 1 &&
                footerSections?.textWithPath?.slice(0, 2).map((item, index) => (
                  <Styled.FootBottomRightLI key={index}>
                    <a
                      href={
                        isExternalUrlHref(item?.path)
                          ? item?.path
                          : addTrailingSlash(
                              process.env.GATSBY_DOMAIN_URL + item?.path
                            )
                      }
                      target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      {item?.text}
                    </a>
                  </Styled.FootBottomRightLI>
                ))}
            </Styled.FootBottomLeftLI>
          </Styled.FootBottomLeftUL>
        </Styled.FootBottomLeft>
        <Styled.FootBottomMiddle>
          <a
            href={
              footerSections?.imageWithPath?.length > 0 &&
              isExternalUrlHref(footerSections?.imageWithPath[0]?.path)
                ? footerSections?.imageWithPath[0]?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL +
                      footerSections?.imageWithPath[0]?.path
                  )
            }
            target={
              footerSections?.imageWithPath?.length > 0 &&
              isExternalUrl(footerSections?.imageWithPath[0]?.path)
                ? '_blank'
                : '_self'
            }
            rel="noreferrer"
          >
            <Styled.FootBottomMiddleImg
              src={
                footerSections?.imageWithPath?.length > 0 &&
                footerSections?.imageWithPath[0]?.image?.file?.url
              }
              alt={
                footerSections?.imageWithPath?.length > 0 &&
                footerSections?.imageWithPath[0]?.image?.title
              }
            />
          </a>
        </Styled.FootBottomMiddle>
        <Styled.FootBottomLeft className="d-block d-lg-none">
          <Styled.FootBottomLeftUL>
            {/* <Styled.FootBottomLeftLI>
              <a
                href={
                  footerSections?.textWithPath?.length > 0 &&
                  isExternalUrlHref(footerSections?.textWithPath[0]?.path)
                    ? footerSections?.textWithPath[0]?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL +
                          footerSections?.textWithPath[0]?.path
                      )
                }
                target={
                  footerSections?.textWithPath?.length > 0 &&
                  isExternalUrl(footerSections?.textWithPath[0]?.path)
                    ? '_blank'
                    : '_self'
                }
                rel="noreferrer"
              >
                {footerSections?.textWithPath?.length > 0 &&
                  footerSections?.textWithPath[0]?.text}
              </a>
            </Styled.FootBottomLeftLI> */}
            {footerSections?.textWithPath?.length > 1 &&
              footerSections?.textWithPath?.slice(0, 2).map((item, index) => (
                <Styled.FootBottomLeftLI key={index}>
                  <a
                    href={
                      isExternalUrlHref(item?.path)
                        ? item?.path
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + item?.path
                          )
                    }
                    target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                    rel="noreferrer"
                  >
                    {item?.text}
                  </a>
                </Styled.FootBottomLeftLI>
              ))}
          </Styled.FootBottomLeftUL>
        </Styled.FootBottomLeft>
        <Styled.FootBottomRight>
          <Styled.FootBottomRightUL>
            {footerSections?.textWithPath?.length > 1 &&
              footerSections?.textWithPath?.slice(2).map((item, index) => (
                <Styled.FootBottomRightLI key={index}>
                  <a
                    href={
                      isExternalUrlHref(item?.path)
                        ? item?.path
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + item?.path
                          )
                    }
                    target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                    rel="noreferrer"
                  >
                    {item?.text}
                  </a>
                </Styled.FootBottomRightLI>
              ))}
          </Styled.FootBottomRightUL>
        </Styled.FootBottomRight>
        <Styled.Clear></Styled.Clear>
        <Styled.FootBottomBottom>
          {documentToReactComponents(
            JSON.parse(footerTrademarkData?.contentDetails?.raw),
            optionsMainStyle
          )}
          <Styled.FootBottomBottomUL>
            {socailMediaImagesData?.listOfImagesWithPath?.map((item, index) => (
              <Styled.FootBottomBottomLI key={index}>
                <a
                  href={
                    isExternalUrlHref(item?.path)
                      ? item?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + item?.path
                        )
                  }
                  target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  <Styled.FootBottomBottomImg
                    src={item?.image?.file?.url}
                    alt={item?.image?.title}
                  />
                </a>
              </Styled.FootBottomBottomLI>
            ))}
          </Styled.FootBottomBottomUL>
        </Styled.FootBottomBottom>
      </Styled.FootBottom>
    </Styled.Foot>
  );
}
